import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isDialogActive: false,
    isMobileMenuActive: false,
    lightboxIndex: 0,
  },
  mutations: {
    toggleDialog: (state) => {
      state.isDialogActive = !state.isDialogActive;
    },
    toggleMobileMenu: (state) => {
      state.isMobileMenuActive = !state.isMobileMenuActive;
    },
    setLightboxIndex: (state, index) => {
      state.lightboxIndex = index;
    },
  },
  actions: {},
  getters: {
    getDialogStatus: (state) => {
      return state.isDialogActive;
    },
    getMobileMenuStatus: (state) => {
      return state.isMobileMenuActive;
    },
    getLightboxIndex: (state) => {
      return state.lightboxIndex;
    },
  },
});
