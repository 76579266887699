<template>
  <div class="wrap">
    <img src="@/assets/svg/line.svg" alt="" class="line" />
    <div class="text-wrapper">
      <div class="text-content">
        <p class="quote">
          {{ body }}
        </p>

        <br />

        <p id="people" class="plain">
          {{ from }}<span v-if="meta">,</span> {{ meta }}
        </p>
        <p id="people" class="mobile">
          {{ from }}<span v-if="meta">,</span><br />
          {{ meta }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    body: String,
    from: String,
    meta: String,
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  .text-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    text-align: justify;
    text-justify: inter-word;
    width: 100%;

    .text-content {
      width: 100%;
    }

    .quote {
      text-indent: none;

      @media (max-width: 900px) {
        text-indent: 1em;
      }
    }
  }

  #people {
    padding-bottom: 2em;
    font-size: 16px;

    @media (max-width: 768px) {
      font-size: 12px;
      line-height: 16px;
    }
  }

  .plain {
    padding-top: 1em;

    @media (max-width: 767px) {
      display: none;
    }
  }

  .mobile {
    @media (min-width: 768px) {
      display: none;
    }
  }

  .line {
    padding: 0.7em 2em 1em 1em;
    @media (max-width: 900px) {
      display: none;
    }
  }
}
</style>