<template>
  <div>
    <Header />
    <div class="text">
      <h1>Privacy Policy</h1>
      <p>
        This privacy policy refers to this website
        [www.weweddingphotography.com] and governs the privacy of its users who
        choose to use it, in compliance with all the provisions of the Law to
        protect privacy, explicitly confirming our careful adherence to the
        provisions of Regulation Gdpr n.679 / 2016 and to what follows, and in
        particular from what is contained in the articles from 13 to 22, and to
        Article 34.
      </p>

      <p>
        At any time, you can contact the owner of the data processing
        We.Weddingphotography.com, by contacting him at the email address:
        info@weweddingphotography.com for any information, correction, variation
        or cancellation you wish on the data concerning you.
      </p>

      <p>
        We guarantee that the data collected will not be used for purposes other
        than those explicitly agreed, which if not explicitly granted will not
        be disclosed to third parties, and that will be stored and protected by
        adopting appropriate procedures.
      </p>

      <h4>What is this privacy policy for?</h4>

      <p>
        The policy sets out the different areas where user privacy is concerned
        and outlines the obligations & requirements of the users, the website
        and website owners. Furthermore the way this website processes, stores
        and protects user data and information will also be detailed within this
        policy.
      </p>

      <h4>The website</h4>

      <p>
        This website and its owners take a proactive approach to user privacy
        and ensure the necessary steps are taken to protect the privacy of its
        users throughout their visiting experience. This website complies to all
        Italy national laws and requirements for user privacy.
      </p>

      <h4>Use of Cookies</h4>

      <p>
        This website uses Cookies to enhance the users experience while visiting
        the website. This website uses a cookie control system allowing the user
        on their first visit to the website to allow or disallow the use of
        Cookies on their computer/device. This complies with recent legislation
        requirements for websites to obtain implied consent from users before
        leaving behind or reading files such as Cookies on a user’s
        computer/device.
      </p>
      <p>
        Cookies are small files saved to the user’s computers hard drive that
        track, save and store information about the user’s interactions and
        usage of the website. This allows the website, through its server to
        provide the users with a tailored experience within this website.
      </p>
      <p>
        Users are advised that if they wish to deny the use and saving of
        Cookies from this website on to their computers hard drive they should
        take necessary steps within their web browsers security settings to
        block all Cookies from this website and its external serving vendors.
      </p>
      <p>
        By using this website you consent to the use of Cookies. Users can
        choose not to accept Cookies at any time by modifying your browser
        settings (if possible), not using the website anymore or by using the
        waiver options applicable. Keep in mind that certain functions are only
        available through the use of Cookies, and choosing not to accept
        Cookies, you may not be able to use these functions.
      </p>

      <h4>Types of Cookies used</h4>

      <h4>Statistical data</h4>

      <p>
        This website uses partner for statistical data, such as Google
        Analytics, to collect statistical and analytical data about the use of
        the website by its visitors. For example, these partners collect and
        compile data on page views on the search engines and keywords they used
        to find your site, on the links which are accessible from the site, and
        other similar data. These statistics are not used to track user activity
        on other websites.
      </p>

      <h4>Third parties content</h4>

      <p>
        This website may contain embedded widget from third parties, such as
        Instagram (Instagram Cookies Policy). By viewing this content or using
        these widgets, the user could accept Cookies from the websites that
        contain them. These Cookies are not under our control and to obtain
        additional information, you should consult the website of the third
        party in question.
      </p>

      <h4>User’s choices with respect to Cookies</h4>

      <p>
        www.giuseppemarano.com recommends not to restrict or block Cookies: in
        doing so, the functionality of our website may not be optimal. However,
        if you choose to do so, you must consider the following. Detailed
        information on Cookies are also available on the website
        www.aboutCookies.org .
      </p>

      <h4>Browser Cookies</h4>

      <p>
        The Help menu in the toolbar of most browsers will tell you how it is
        possible not to accept Cookies, how to receive a warning from your
        browser when you receive a new cookie, or how to delete or disable
        Cookies altogether. This is a list of instructions to manage cookies for
        the most used browsers:
      </p>

      <ul>
        <li>google chrome</li>
        <li>mozilla firefox</li>
        <li>apple safari</li>
        <li>internet explorer</li>
      </ul>

      <h4>Statistical data</h4>

      <p>
        To block Google Analytics Cookies, please visit:
        <a href="http://tools.google.com/dlpage/gaoptout"
          >http://tools.google.com/dlpage/gaoptout</a
        >.
      </p>

      <h4>Contact & communication</h4>

      <p>
        Users contacting this website and/or its owners do so at their own
        discretion and provide any such personal details requested at their own
        risk. Your personal information is kept private and stored securely
        until a time it is no longer required or has no use. Every effort has
        been made to ensure a safe and secure form to email submission process
        but advise users using such form to email processes that they do so at
        their own risk.
      </p>

      <p>
        This website and its owners use any information submitted to provide you
        with further information about the products/services they offer or to
        assist you in answering any questions or queries you may have submitted.
        Your details are not passed on to any third parties.
      </p>

      <h4>External links</h4>

      <p>
        Although this website only looks to include quality, safe and relevant
        external links users should always adopt a policy of caution before
        clicking any external web links mentioned throughout this website.
      </p>

      <p>
        The owners of this website cannot guarantee or verify the contents of
        any externally linked website despite their best efforts. Users should
        therefore note they click on external links at their own risk and this
        website and its owners cannot be held liable for any damages or
        implications caused by visiting any external links mentioned.
      </p>

      <h4>Social media platforms</h4>

      <p>
        Communication, engagement and actions taken through external social
        media platforms that this website and its owners participate on are
        custom to the terms and conditions as well as the privacy policies held
        with each social media platform respectively.
      </p>

      <p>
        Users are advised to use social media platforms wisely and
        communicate/engage upon them with due care and caution in regard to
        their own privacy and personal details. This website nor its owners will
        ever ask for personal or sensitive information through social media
        platforms and encourage users wishing to discuss sensitive details to
        contact them through primary communication channels such as by telephone
        or email.
      </p>

      <p>
        This website may use social sharing buttons which help share web content
        directly from web pages to the social media platform in question. Users
        are advised before using such social sharing buttons that they do so at
        their own discretion and note that the social media platform may track
        and save your request to share a web page respectively through your
        social media platform account.
      </p>

      <h4>Shortened links in social media</h4>

      <p>
        This website and its owners through their social media platform accounts
        may share web links to relevant web pages. By default some social media
        platforms shorten lengthy urls [web addresses] (this is an example:
        http://goo.gl/).
      </p>

      <p>
        Users are advised to take caution and good judgement before clicking any
        shortened urls published on social media platforms by this website and
        its owners. Despite the best efforts to ensure only genuine urls are
        published many social media platforms are prone to spam and hacking and
        therefore this website and its owners cannot be held liable for any
        damages or implications caused by visiting any shortened links.
      </p>

      <h4>Resources and further information</h4>

      <ul>
        <li>italian privacy law 196/03</li>
        <li>eu cookie law in italy</li>
        <li>facebook privacy policy</li>
        <li>instagram privacy policy</li>
        <li>google privacy policy</li>
      </ul>
    </div>
  </div>
</template>

<script>
import Header from "../components/Header.vue";

export default {
  components: { Header },
};
</script>

<style lang="scss" scoped>
.text {
  margin: auto;
  max-width: 100ch;
  padding: 2em;
  padding-top: 0em;
}

h1 {
  padding: 1em;
  padding-top: 0em;
}

ul {
  list-style: none;
}

li {
  font-weight: 700;
  line-height: 1.25rem;
}

a {
  text-decoration: underline;
}
</style>