<template>
  <div id="footer">
    <div id="bottom-footer">
      <ul>
        <li><a href="tel:+39 3403506186 ">M.+39 3403506186</a></li>
        <li>
          <a href="mailto:info@weweddingphotography.com"
            >info@weweddingphotography.com</a
          >
        </li>
        <li>Vat N. 02470340031</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>


<style lang="scss" scoped>
#footer {
  font-size: 18px;
  line-height: 1rem;
}

ul {
  max-width: 70em;

  @media (max-width:1440px) {
    max-width: 80%;
  }

  text-align: center;
  margin: auto;

  padding-left: 0;

  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 2em 0em;
  line-height: 2rem;
}

@media (max-width: 900px) {
  ul {
    flex-direction: column;
    padding-top: 2em;
    font-size: 16px;
    line-height: 32px; 
  }
}

@media (max-width:768px) {
  ul {  
    padding-top: 0em;
    font-size: 15px;
  }
}

</style>