<template>
  <div>
    <Header />
    <div id="hero-image">
      <img
        src="@/assets/testimonials/C+J_059.jpg"
        alt="hero image"
        width="100%"
      />
    </div>
    <div class="container">
      <div id="hero-box">
        <div class="text">
          <h1>Testimonials</h1>

          <div v-for="(t, i) in testimonials" :key="i">
            <Testimonial :from="t.title" :body="t.contentBody" :meta="t.contentMeta" />
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";

import Testimonial from '../components/Testimonial.vue'
import Header from "../components/Header.vue";

export default {
  components: { Header, Testimonial },
  data() {
    return {
      testimonials: [],
    };
  },
  apollo: {
    testimonials: {
      query: gql`
        query getTestimonials {
          testimonials {
            nodes {
              contentBody
              contentMeta
              title
            }
          }
        }
      `,
      update: (data) =>  data.testimonials.nodes,
    },
  },
};
</script>

<style lang="scss" scoped>
#hero-box {
  .text {
    max-width: 47.5em;

    @media (max-width: 900px) {
      max-width: 75%;
    }
  }
}

#hero-image {
  line-height: 0;
}

.container {
  @media (max-width: 768px) {
    padding-bottom: 3em;
  }
  padding-bottom: 0em;
}

h1 {
  padding-bottom: 0.5em;
}


</style>