<template>
  <div>
    <Header />
    <div class="page">
      <div class="text">
        <h1>{{ content.title }}</h1>
        <div v-html="content.content"></div>
      </div>

      <div class="gallery">
        <div class="row-wrapper">
          <div class="single-row">
            <ImgLightboxed
              v-for="(img, i) in content.gallery"
              :key="i"
              :src="img"
              :index="i"
            />
          </div>
        </div>
      </div>
    </div>

    <Dialog>
      <Slider
        :delay="5000"
        :slides="content.gallery"
        :autoLoop="false"
        :showNav="true"
        :showCounter="true"
        :realHeight="true"
      />
    </Dialog>
  </div>
</template>

<script>
import gql from "graphql-tag";

import ImgLightboxed from "../components/ImgLightboxed.vue";
import Dialog from "../components/Dialog";
import Slider from "../components/Slider";

import Header from "../components/Header.vue";

export default {
  components: { ImgLightboxed, Dialog, Slider, Header },
  data() {
    return {
      isActive: true,
      currSlide: 0,
      content: {},
      images: [],
    };
  },
  apollo: {
    content: {
      query: gql`
        query getWeddingContent($id: ID!) {
          content: wedding(id: $id) {
            title
            content
            gallery
          }
        }
      `,
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  padding-bottom: 2em;
}

.gallery {
  margin: auto;
  width: 85%;
  max-width: 85em;
  @media (max-width: 1024px) {
    width: 80%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  .single-row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-column-gap: 50px;
    grid-row-gap: 50px;

    margin-bottom: 50px;

    @media (max-width: 1024px) and (min-width: 768px) {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr;
      grid-column-gap: 50px;
      grid-row-gap: 50px;

      .first {
        //grid-column: 1 / span 1;
        //grid-row: 1 / span 2;
      }

      .second {
        //grid-column: 2 / span 1;
        //grid-row: 1 / span 2;
      }
    }

    @media (max-width: 768px) {
      display: block;
      margin-bottom: 0;

      img {
        margin-bottom: 35px;
      }
    }
  }
}

.text {
  max-width: 50em;
  margin: auto;
  padding: 0em 2em;
  padding-bottom: 3em;

  @media (max-width: 1028px) {
    padding: 0em;
    max-width: 75%;
    margin: auto;
    text-align: justify;
    padding-bottom: 1.5em;
  }

  @media (max-width: 600px) {
    max-width: 80%;
    padding-bottom: 2em;
  }

  h1 {
    padding-top: 1.5em;
    padding-bottom: 1.5em;
    margin: 0;
  }
}
</style>