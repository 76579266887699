<template>
  <div :class="'dialog wrapper ' + (isActive ? 'active' : '')">
    <div class="exit" @click="closeDialog">
      <svg
        width="29"
        height="29"
        viewBox="0 0 29 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="6.71729"
          y="20.8596"
          width="20"
          height="1"
          transform="rotate(-45 6.71729 20.8596)"
          fill="black"
        />
        <rect
          x="7.77832"
          y="6.36389"
          width="20"
          height="1"
          transform="rotate(45 7.77832 6.36389)"
          fill="black"
        />
      </svg>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  computed: {
    isActive() {
      return this.$store.getters.getDialogStatus;
    },
  },
  methods: {
    closeDialog: function () {
      this.$store.commit("toggleDialog");
    },
  },
};
</script>

<style lang="scss" scoped>

.wrapper {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  background-color: white;
  display: none;
  z-index: 1000;


  &.active {
    display: block;
  }
}



</style>