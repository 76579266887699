<template>
  <div id="app">
    <router-view />
    <Footer />
  </div>
</template>


<script>
import Footer from "@/components/Footer.vue";

export default {
  components: { Footer },
};
</script>

<style lang="scss">
#app {
  color: #000000;
  text-align: left;

  min-height: 100vh;
  position: relative;
}

p {
  margin: auto;
}

h1 {
  text-align: center;
}

#nav {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: right;
  text-align: right;

  margin: auto;
  h1 {
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.813rem;
    text-align: left;
  }
  #nav-items {
    list-style: none;
    a {
      font-size: clamp(1rem, 1vw, 1.125rem);
      font-style: normal;
      font-weight: 400;
      line-height: 1.75rem;
      color: #000000;

      &.router-link-exact-active {
        color: #b84631;
        font-style: italic;
      }
    }
  }
}

#hero-box {
  background-color: #b84631;
  cursor: url(./assets/svg/cursor_black.svg), auto;

  text-align: left;
  margin: auto;
  display: flex;

  h1 {
    font-size: 28px;
  }

  p {
    font-size: 18px;
    line-height: 24px;
  }

  .text {
    max-width: 45em;

    margin: auto;
    padding: 10em 0em;

    @media (max-width: 768px) {
      max-width: 65%;
      margin: auto;
      padding: 4em 0em;

      h1 {
        font-size: 18px;
        line-height: 20px;
      }

      p {
        font-size: 14px;
        line-height: 18px;
      }
    }

    @media (max-width: 600px) {
      max-width: 75%;
      padding: 4em 0em;
    }
  }

  #people {
    text-align: right;
  }
}

a:link {
  text-decoration: none;
  color: #000000;
  &:hover {
    font-style: italic;
  }
}

a:visited {
  color: #000000;
}

.exit {
  margin-top: 5vh;
  margin-left: 5vw;

  cursor: default;
}
</style>
