<template>
  <div>
    <Header />
    <div class="page">
      <div class="text">
        <h1>{{ content.title }}</h1>
        <div v-html="content.body"></div>
      </div>

      <div class="gallery">
        <div class="row-wrapper">
          <div v-for="(imagesRow, iR) in images" :key="iR" class="single-row">
            <ImgLightboxed
              v-for="(img, i) in imagesRow"
              :key="i"
              :src="img.photo"
              :class="img.format"
              :index="i + (4 * iR)"
            />
          </div>
        </div>
      </div>
    </div>

    <Dialog>
      <Slider
        :delay="5000"
        :slides="images.flat() | extractPhotos"
        :autoLoop="false"
        :showNav="true"
        :showCounter="true"
        :realHeight="true"
      />
    </Dialog>
  </div>
</template>

<script>
import gql from "graphql-tag";

import ImgLightboxed from "../components/ImgLightboxed.vue";
import Dialog from "../components/Dialog";
import Slider from "../components/Slider";

import Header from "../components/Header.vue";

export default {
  components: { ImgLightboxed, Dialog, Slider, Header },
  data() {
    return {
      images: [],
      isActive: true,
      currSlide: 0,
      content: "",
    };
  },
  apollo: {
    content: gql`
      query getPortfolioContent {
        content: page(id: "22", idType: DATABASE_ID) {
          body: content(format: RENDERED)
          title: contentTitle
        }
      }
    `,
    images: {
      query: gql`
        query getPortoflioPhotos {
          images: portfolios(before: "today") {
            nodes {
              photo
              format
            }
          }
        }
      `,
      update: (data) => {
        const images = data.images.nodes;

        let reservedSpace = 0;
        let childArray = [];
        let newImagesArray = [];
        let overflowCache = [];

        if (images.length > 5) {
          for (let i = 0; i < images.length; i++) {
            if (reservedSpace < 4 && overflowCache.length) {
              childArray.push(overflowCache[0]);

              overflowCache.splice(0, 1);
              reservedSpace += 2;
            }

            reservedSpace++;

            if (images[i].format === "portrait") {
              reservedSpace++;
            }

            childArray.push(images[i]);

            if (reservedSpace === 7) {
              childArray.pop();
              overflowCache.push(images[i]);
              reservedSpace -= 2;
            }

            if (reservedSpace === 6) {
              newImagesArray.push(childArray);
              childArray = [];
              reservedSpace = 0;
            } else if (i === images.length - 1) {
              newImagesArray.push(childArray);
            }
          }
        } else {
          return [images];
        }

        return newImagesArray;
      },
    },
  },
  filters: {
    extractPhotos: function (value) {
      let array = [];
      for (let i = 0; i < value.length; i++) {
        array.push(value[i].photo);
      }

      return array;
    },
  },
  // mounted() {
  //   const portraitElements = document.getElementsByClassName("portrait");
  //   console.log(portraitElements);

  //   for (let i = 0; i < portraitElements.length; i++) {
  //     console.log(portraitElements[i]);
  //     portraitElements[i].style.height = (portraitElements[i].height * 2) + 'px';
  //     console.log(portraitElements[i]);
  //   }
  //   console.log(portraitElements);
  // },
};
</script>

<style lang="scss" scoped>
.page {
  padding-bottom: 2em;
}

.gallery {
  margin: auto;
  width: 85%;
  max-width: 85em;
  @media (max-width: 1024px) {
    width: 80%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  .single-row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-column-gap: 50px;
    grid-row-gap: 50px;

    margin-bottom: 50px;

    height: auto;

    .portrait:nth-child(1) {
      grid-column: 1 / span 1;
      grid-row: 1 / span 2;
    }

    .portrait:nth-child(2) {
      grid-column: 2 / span 1;
      grid-row: 1 / span 2;
    }

    .portrait:nth-child(3) {
      grid-column: 3 / span 1;
      grid-row: 1 / span 2;
    }

    .first {
      //grid-column: 1 / span 1;
      //grid-row: 1 / span 2;
    }

    .second {
      //grid-column: 2 / span 1;
      //grid-row: 1 / span 2;
    }

    .third {
      //grid-column: 3 / span 1;
      //grid-row: 1 / span 2;
    }

    // @media (max-width: 1024px) and (min-width: 768px) {
    //   grid-template-columns: 1fr 1fr;
    //   grid-template-rows: 1fr 1fr 1fr;
    //   grid-column-gap: 50px;
    //   grid-row-gap: 50px;

    //   .first {
    //     //grid-column: 1 / span 1;
    //     //grid-row: 1 / span 2;
    //   }

    //   .second {
    //     //grid-column: 2 / span 1;
    //     //grid-row: 1 / span 2;
    //   }
    // }

    @media (max-width: 768px) {
      display: block;
      margin-bottom: 0;

      img {
        margin-bottom: 35px;
      }
    }
  }
}

.text {
  max-width: 50em;
  margin: auto;
  padding: 0em 2em;
  padding-bottom: 3em;

  @media (max-width: 1028px) {
    padding: 0em;
    max-width: 75%;
    margin: auto;
    text-align: justify;
    padding-bottom: 1.5em;
  }

  @media (max-width: 600px) {
    max-width: 80%;
    padding-bottom: 2em;
  }

  h1 {
    padding-top: 1.5em;
    padding-bottom: 1.5em;
    margin: 0;
  }
}
</style>