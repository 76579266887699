<template>
  <div>
    <form action="">
      <div class="double-entry">
        <input
          class="input-form"
          type="text"
          name=""
          id=""
          placeholder="Name*"
        />

        <input
          class="input-form"
          type="text"
          name=""
          id=""
          placeholder="Last Name*"
        />
      </div>

      <input
        class="input-form"
        type="email"
        name=""
        id=""
        placeholder="E-mail*"
      />
      <br />
      <textarea
        class="input-form textarea"
        type="text"
        name=""
        placeholder="Tell me about your wedding day"
      />
      <div class="double-entry">
        <input class="input-form" type="" name="" id="" placeholder="Date" />
        <input
          class="input-form"
          type="text"
          name=""
          id=""
          placeholder="Location"
        />
      </div>

      <input
        class="input-form"
        type="text"
        name=""
        id=""
        placeholder="How did you find us?"
      />

      <div class="bottom-form">
        <div class="privacy">
          <input type="checkbox" class="checkbox-button" name="" id="" />

          <p>
            I accept the
            <u
              ><router-link target="_blank" to="/Privacy"
                >Privacy Policy</router-link
              ></u
            >
          </p>
        </div>

        <button @click="sendEmail"><div class="text-button">SEND</div></button>
      </div>
    </form>
  </div>
</template>

<script>
import gql from "graphql-tag";

export default {
  methods: {
    sendEmail() {
      this.$apollo
        .mutate({
          mutation: gql` mutation SEND_EMAIL {
            sendEmail(
              input: {
              to: "lotroayoub@gmail.com"
              from: "test@admin.dev-wewedding.we-bg.it"
              subject: "test email"
              body: "test email"
              clientMutationId: "test"
              }
            ) {
                origin
                sent
                message
            }
        }`,
        })
        .then((data) => {
          // Result
          console.log(data);
        })
        .catch((error) => {
          // Error
          console.error(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
hr {
  width: 100%;
  border-color: black;
  margin-top: 55px;
  border-bottom: none;
}

.form-text {
  text-align: left;
  margin: 0;
}

textarea,
input {
  outline: none;
}

input,
textarea {
  font-family: Hercules;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: black;

  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 16px;
  }
}

.input-form {
  border: 0;
  border-bottom: 1px solid #000000;
  width: 100%;
  height: 40px;
}

.textarea {
  height: 100px;
  vertical-align: top;
}

button {
  background-color: white;
  border: 1px solid #000000;
  padding: 0.5em 1.375em;
  .text-button {
    font-size: 18px;
    font-weight: 400;
    line-height: 1.563rem;
    margin: 0;
    @media (max-width: 768px) {
      font-size: 16px;
    }
  }
}

form {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.bottom-form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding-top: 0.5em;
}

.checkbox-button {
  margin: 0em 0.5em 0em 0em;

  border-radius: 0;
  border-color: black;
  appearance: none;
  border: 1px solid;
  width: 16px;
  height: 16px;
}

.checkbox-button:checked {
  background-image: url("../assets/check-symbol.png");
  background-size: cover;
}

div {
  margin: 1em 0em;
}

.double-entry {
  display: flex;
  flex-direction: row;

  .input-form {
    &:first-child {
      margin-right: 10px;
    }
  }
}

.privacy {
  display: inline-flex;
}
</style>