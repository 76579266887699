<template>
  <div class="slider">
    <div class="slider-wrapper">
      <div v-if="showNav" class="nav-button prev" @click="prevSlide">
        <svg
          width="21"
          height="15"
          viewBox="0 0 21 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M7 14L1 7.5L7 0.999999" stroke="black" />
          <path d="M1 7.5H21" stroke="black" />
        </svg>
      </div>
      <div
        @click="nextSlide"
        class="slider-container"
        v-touch:swipe.left="nextSlide"
        v-touch:swipe.right="prevSlide"
      >
        <p v-if="!slides.length" class="error">Add slides to the slider</p>

        <div v-for="(img, i) in slides" class="img-container" :key="i">
          <img
            :class="'slide ' + (currSlide === i ? 'active' : '')"
            :src="img"
            alt=""
          />
        </div>
      </div>

      <div v-if="showNav" class="nav-button next" @click="nextSlide">
        <svg
          width="21"
          height="15"
          viewBox="0 0 21 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M14 14L20 7.5L14 1" stroke="black" />
          <path d="M20 7.5H-5.96046e-07" stroke="black" />
        </svg>
      </div>
    </div>

    <div v-if="showCounter" class="counter">
      {{ currSlide + 1 }}/ {{ slides.length }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    slides: {
      type: Array,
      default: () => {
        return [];
      },
    },
    delay: {
      type: Number,
      default: () => {
        return 5000;
      },
    },
    realHeight: Boolean,
    showNav: Boolean,
    autoLoop: Boolean,
    showCounter: Boolean,
  },
  data() {
    return {
      loopInterval: null,
      imgHeight: null,
      currSlide: 0,
    };
  },
  computed: {
    sliderIndex() {
      return this.$store.getters.getLightboxIndex;
    },
  },
  watch: {
    sliderIndex: function (newV) {
      this.currSlide = newV;
    },
  },
  methods: {
    loopSlides: function () {
      if (this.autoLoop) {
        let i = this.currSlide + 1;

        if (this.loopInterval) {
          clearInterval(this.loopInterval);
        }

        this.loopInterval = setInterval(() => {
          this.currSlide = i;

          i = this.nextIndex(i);
        }, this.delay);
        return;
      }
    },
    nextSlide: function () {
      this.currSlide = this.nextIndex(this.currSlide);
      this.loopSlides();
    },
    prevSlide: function () {
      this.currSlide = this.prevIndex(this.currSlide);
      this.loopSlides();
    },
    nextIndex: function (index) {
      let i = index;

      if (i < this.slides.length - 1) {
        i++;
      } else {
        i = 0;
      }

      return i;
    },
    prevIndex: function (index) {
      let i = index;

      if (i >= 1) {
        i--;
      } else if (i === 0) {
        i = this.slides.length - 1;
      }
      return i;
    },
    fixHeight: function () {
      const imgElements = document.getElementsByClassName("img-container");

      console.log("ssdc", imgElements);

      this.imgHeight = imgElements[this.currSlide].offsetHeight;
    },
  },

  mounted() {
    this.loopSlides();

    if (this.realHeight) {
      this.fixHeight();
    }
  },

  updated() {
    if (this.realHeight) {
      this.fixHeight();
    }
  },
};
</script>



<style lang="scss" >
.dialog {
  .slider {
    margin: auto;
    width: 80vw;
    display: flex;
    height: 90vh;
    flex-flow: column;

    // @media (min-width: 1440px) {
    //   width: 80vw;
    //   padding: 2em;
    // }

    // @media (max-width: 1440px) {
    //   width: 85vw;
    // }

    @media (max-width: 768px) {
      width: 100vw;
      .nav-button {
        display: none;
      }

      .slider-wrapper {
        //height: 90vh;

        .slider-container {
          display: flex;
          flex-flow: row;
          align-items: center;
        }
      }
    }

    @media (max-width: 425px) {
      width: 100vw;
    }
  }
}

.slider-wrapper {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  height: 80vh;

  // @media (max-width: 1024px) {
  //   height: 70vh !important;
  // }

  // @media (max-width: 900px) {
  //   .nav-button {
  //     display: none;
  //   }
  // }

  // @media (max-width: 768px) {
  //   height: 50vh !important;
  // }

  // @media (max-width: 425px) {
  //   height: 40vh !important;
  // }

  .slider-container {
    height: auto;
    width: 100%;
    position: relative;
    align-self: flex-start;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80vh;

    .img-container {
      height: fit-content;
      width: 100%;

      position: absolute;

      @media (max-width: 768px) {
        height: fit-content;
      }

      @media (min-width: 1080px) {
        height: 100%;
        width: fit-content;
      }

      .slide {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        opacity: 0;
        transition: 1000ms ease-in-out;

        &.active {
          opacity: 1;
        }
      }
    }
  }

  .nav-button {
    margin: 0em 2em 0em 2em;
  }
}

.counter {
  text-align: center;
  margin-top: auto;
  padding-bottom: 1.1em;
  font-size: 18px;
  align-self: center;
  justify-self: flex-end;

  @media (max-width: 768px) {
    font-size: 16px;
  }
}

.error {
  text-align: center;
}
</style>