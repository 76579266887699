<template>
  <div id="CTA">
    <p>For any information or curiosity</p>

    <a href="mailto:info@weweddingphotography.com">Keep in Touch</a>

    <p>©we.weddingphotography 2021</p>


    <a href="https://www.instagram.com/we.weddingphotography/" target="_blank"
      >Instagram</a
    >
    

    <p>Thank you for watching.</p>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
* {
  text-align: center;
}
div {
  padding: 5em 0em;

    p {
      font-size: 18px;
      padding: 2em 0em;

      @media (max-width:768px) {
        padding: 1.5em 0em;
        font-size: 14px;
        line-height: 16px;
    }
  }

  h4,
  a {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    @media (max-width:768px) {
        font-size: 22px;
        line-height: 20px;
    }
  }

}
</style>