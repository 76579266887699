import Vue from 'vue'
import App from './App.vue'
import router from './router'
import "./style/global.css";
import store from './store'
import Vue2TouchEvents from 'vue2-touch-events'
import { createProvider } from './vue-apollo'

Vue.use(Vue2TouchEvents)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  apolloProvider: createProvider(),
  render: h => h(App)
}).$mount('#app')

