<template>
  <div class="weddings menu">
    <div class="top-bar">
      <BackButton />
      <div id="nav">
        <nav>
          <ul id="nav-items">
            <li v-for="(wedding, i) in weddings" :key="i">
              <router-link :to="{ name: 'Wedding', params: { id: wedding.id }}" :param="wedding.id"
                >{{wedding.title}}</router-link
              >
            </li>
          </ul>
        </nav>
      </div>
      <router-view />
    </div>

    <div class="image-show">
      <div class="image left">
        <img src="@/assets/Home/005.jpg" alt="" />
      </div>

      <div class="image right">
        <img src="@/assets/weddings/C+J/C+G_065.jpg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";

import BackButton from "../components/BackButton.vue";

export default {
  components: { BackButton },
  data() {
    return {
      weddings: [],
    };
  },
  apollo: {
    weddings: {
      query: gql`
        query getWeddings {
          weddings {
            nodes {
              id
              title
            }
          }
        }
      `,
      update: (data) => data.weddings.nodes,
    },
  },
};
</script>


<style lang="scss" scoped>
.top-bar {
  display: flex;
  flex-direction: column;
  #nav {
    margin-left: auto;
    margin-right: 7.7%;

    #nav-items {
      list-style: none;
      a {
        font-size: clamp(1rem, 1vw, 1.125rem);
        font-style: normal;
        font-weight: 400;
        line-height: clamp(1.75rem, 1vw, 2rem);
        color: #000000;

        &.router-link-exact-active {
          color: #b84631;
          font-style: italic;
        }

        &:hover {
          font-style: italic;
        }
      }
    }
  }

  @media (min-width: 768px) {
    #nav {
      margin-left: auto;
    }
  }
}

.weddings.menu {
  width: 100vw;
  height: 80vh;
}

.image-show {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;

  @media (max-width: 900px) {
    display: none;
  }

  .image {
    position: absolute;
    width: auto;

    &.left {
      left: 12%;
      top: 22%;
      width: 33%;
    }

    &.right {
      right: 25%;
      bottom: 5%;
      width: 27%;
    }

    img {
      width: 80%;
    }
  }
}
</style>