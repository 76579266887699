<template>
  <div class="exit" @click="close">
    <img src="@/assets/svg/arrow.svg" alt="" />
  </div>
</template>

<script>
export default {
  methods: {
    close: function () {
      window.history.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>