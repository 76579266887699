<template>
  <div class="loader" v-if="$apollo.loading">
    <section class="mod model">
      <div class="spinner"></div>
    </section>
  </div>
  <div v-else class="home">
    <Header />
    <div id="image-slider">
      <Slider
        :delay="5000"
        :slides="content.slider | cleanArray"
        :autoLoop="true"
        :counter="false"
      />
    </div>

    <div id="hero-box">
      <div class="text">
        <h1>{{ content.title }}</h1>

        <div v-html="content.body"></div>
        <br />
        <div class="published">
          <p id="pub">Published on:</p>
          <div class="logo">
            <a href="https://togetherjournal.com/" target="_blank">
              <img src="@/assets/logo.svg" alt="logo" class="logo" />
            </a>
          </div>
        </div>
      </div>
    </div>

    <CTA />
  </div>
</template>

<script>
import gql from "graphql-tag";

import Header from "../components/Header.vue";

// @ is an alias to /src
import CTA from "@/components/CTA.vue";
import Slider from "@/components/Slider.vue";

export default {
  components: {
    CTA,
    Slider,
    Header,
  },
  data() {
    return {
      content: "",
    };
  },
  apollo: {
    content: gql`
      query getHomeContent {
        content: page(id: "15", idType: DATABASE_ID) {
          body: content(format: RENDERED)
          title: contentTitle
          slider: contentSliderImages
        }
      }
    `,
  },
  filters: {
    cleanArray: function (value) {
      return value.filter((el) => el != null);
    },
  },
};
</script>

<style lang="scss" scoped>
#image-slider {
  line-height: 0;
}

#hero-box {
  .text {
    h1 {
      text-align: left;
      margin-top: 0em;
      padding-bottom: 0.25em;
    }

    .published {
      padding-top: 5em;

      @media (max-width: 768px) {
        padding-top: 2em;
      }

      .logo {
        padding-top: 0.15em;
        width: 55%;

        @media (max-width: 1024px) {
          width: 60%;
        }
        @media (max-width: 768px) {
          width: 60%;
        }
        @media (max-width: 425px) {
          width: 75%;
        }

        @media (max-width: 375px) {
          width: 80%;
        }
      }
    }
  }
}

section.spinner {
  height: 250px;
  //padding-top:90px;
  float: left;
  width: 50%;
  position: relative;
}
.spinner {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  margin: auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.model {
  background: #4b346f;
  .spinner {
    border: 1px solid;
    animation: rotate 1.3s linear 0s infinite;
    &:after {
      content: "";
      position: absolute;
      width: 15px;
      height: 15px;
      background: #b84631;
      border-radius: 100%;
      left: -5px;
      top: 18px;
    }
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>