<template>
  <div :class="'dialog wrapper ' + (isActive ? 'active' : '')">
    <div class="exit" @click="closeDialog">
      <svg
        width="29"
        height="29"
        viewBox="0 0 29 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="6.71729"
          y="20.8596"
          width="20"
          height="1"
          transform="rotate(-45 6.71729 20.8596)"
          fill="black"
        />
        <rect
          x="7.77832"
          y="6.36389"
          width="20"
          height="1"
          transform="rotate(45 7.77832 6.36389)"
          fill="black"
        />
      </svg>
    </div>
    <div id="nav">
      <nav>
        <ul id="nav-items" @click="closeDialog">
          <li><router-link to="/Weddings">weddings</router-link></li>
          <li>
            <router-link to="/Portfolio">portfolio | analog</router-link>
          </li>
          <li><router-link to="/Testimonials">testimonials</router-link></li>
          <li><router-link to="/Contact">contact</router-link></li>
        </ul>
      </nav>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from "./Footer.vue";
export default {
  components: { Footer },
  methods: {
    closeDialog: function () {
      this.$store.commit("toggleMobileMenu");
    },
  },
  computed: {
    isActive() {
      return this.$store.getters.getMobileMenuStatus;
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog.wrapper {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  background-color: white;
  display: none;
  z-index: 1000;

  &.active {
    display: block;
  }
}
#nav {
  display: block;
  margin-left: auto;
  margin-right: 7.7%;
  ul {
    margin-right: 0;
    margin-top: 0;
  }
}

#footer {
  position: absolute;
  width: 100%;
  bottom: 0;
}
</style>