<template>
  <img :src="src" alt="" @click="openLightbox" />
</template>

<script>
export default {
  props: {
    src: String,
    index: {
      default: 0,
      type: Number
    },
  },
  methods: {
    openLightbox: function () {
      this.$store.commit("setLightboxIndex", this.index);
      this.$store.commit("toggleDialog");
    },
  },
};
</script>