<template>
  <div>
    <!-- <CloseButton /> -->
    <Header />

    <div id="contact">
      <h1>Contact</h1>

      <div class="text">
        <p>
          We. wedding photography project is born from the joined visions of
          Gianluigi and Carlotta. We’re based in Italy, a place we deeply love.
          We document your story merging our personal inclinations in one
          language.
        </p>
        <p>
          Our images are the result of a visual culture that has matured over
          time and is constantly evolving thanks to our attention and
          sensibility towards photography discovered on books, movies, tv series
          and studying the great masters of the past.
        </p>
      </div>

      <div id="ciao">
        <div class="p1">
            <h2>Ciao!</h2>

        <p>If you are here is probably because you appreciate our style!</p>

        <p>
          We are curious to know a little bit more about you and your special
          day.
        </p>

        <br>

        <p>Let a message or call us!</p>

        <br />
        </div>

        <p class="links">
          <a href="mailto:info@weweddingphotography.com"
            >info@weweddingphotography.com</a
          >
          <br />
          <a href="tel:+393484817061">+39 3484817061</a>
        </p>

        <br />

        <p>you could use out format:</p>
      </div>

      <Form />
    </div>
  </div>
</template>

<script>
import Form from "../components/Form.vue";
// import CloseButton from "../components/CloseButton.vue";
import Header from '../components/Header.vue';

export default {
  components: { Form, Header },
};
</script>

<style lang="scss" scoped>
#contact {
  max-width: 37.5em;
  margin: auto;
  padding: 2em;
  padding-top: 0em;
  padding-bottom: 12em;
  text-align: justify;
  letter-spacing: -0.03em;

  @media (max-width:768px) {
    padding-bottom: 2em;
  }

  h1 {
    padding-bottom: 1em;
    margin-top: 1.5em;
    margin-bottom: 0;
  }

  .text {
    padding: 1em 0em;
  }
}

#ciao {
  
  .p1 {
    max-width: 90%;
    margin: auto;
  }

  h2 {
    font-size: clamp(1rem, 2.5vw, 1.625rem);
    font-weight: 700;
    line-height: 1.5rem;
    text-align: center;
    padding-bottom: 0.2em;
  }

  p {
    text-align: center;
  }

  a {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
  }
  .links {
    padding: 0em;
    padding-bottom: 1em;
  }

  @media (max-width:768px) {
    a {
      font-size: 16px;
      line-height: 28px;
    }
  }
}
</style>
