<template>
  <div id="top-bar">
    <div id="nav">
      <h1 id="title">
        <router-link to="/">We.wedding photography</router-link>
      </h1>
      <nav>
        <ul id="nav-items" class="desktop">
          <li><router-link to="/Weddings">weddings</router-link></li>
          <li>
            <router-link to="/Portfolio">portfolio | analog</router-link>
          </li>
          <li><router-link to="/Testimonials">testimonials</router-link></li>
          <li><router-link to="/Contact">contact</router-link></li>
        </ul>

        <div class="hamburger mobile" @click="openDialog">
          <img src="@/assets/svg/hamburger.svg" alt="" />
        </div>
      </nav>
    </div>
    <MobileMenu />
  </div>
</template>

<script>
import MobileMenu from "./MobileMenu";

export default {
  name: "Header",
  components: {
    MobileMenu,
  },
  methods: {
    openDialog: function () {
      this.$store.commit("toggleMobileMenu");
    },
  },
};
</script>

<style lang="scss" scoped>
#nav {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  text-align: right;
  max-width: 90em;
  margin: auto;
  padding-top: 4em;
  padding-bottom: 4em;

  #title {
    font-size: 26px;
    @media (max-width: 768px) {
      font-size: 18px;
    }
  }

  @media (max-width: 1680px) {
    max-width: 80%;
    padding: 3em 0em;
  }

  @media (max-width: 768px) {
    padding: 1.5em 0em;
  }
  h1 {
    font-size: 32px;
    font-weight: 400;
    line-height: 1.813rem;
    text-align: left;
  }
  #nav-items {
    list-style: none;
    a {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      color: #000000;

      &.router-link-exact-active {
        color: #b84631;
        font-style: italic;
      }

      &:hover {
        font-style: italic;
      }
    }
  }

  nav {
    display: flex;
  }

  .mobile {
    display: none;
  }

  @media (max-width: 768px) {
    .desktop {
      display: none;
    }

    .mobile {
      display: block;
      margin: auto;
    }
  }
}
</style>
